.videoResponsive {
    overflow: hidden;
    position: static;
    height: 335px;
    width: 560px;
}

.videoResponsive iframe {
    left: 0;
    top: 0;
    height: 335px;
    width: 560px;
    /* position: static; */
}  

@media only screen and (max-width: 720px) {
    .videoResponsive {
        height: fit-content;
        width: fit-content;
    }
    .videoResponsive iframe {
        height: 100%;
        width: 100%;
    }
}