.sagaDayaliKapatmaDugmesiCls {
    display: block;
    color: var(--colorBlack);
    position: absolute;
    left: 101%;
    top: -15%;
    cursor: pointer;
    border: black solid 1px;
    width: 1.25rem;
    border-radius: 100%;
    text-align: center;
}

.solaDayaliKapatmaDugmesiCls {
    display: block;
    color: var(--colorBlack);
    position: absolute;
    left: -5%;
    top: -10%;
    cursor: pointer;
    border: black solid 1px;
    width: 1.25rem;
    border-radius: 100%;
    text-align: center;
}

@media only screen and (max-width: 720px) {
    .solaDayaliKapatmaDugmesiCls {
        left: 0%;
        top: -15%;
    }
}