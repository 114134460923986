.baglantilarCls {
    display: inline-block; 
    color: var(--colorBlack);
    margin-left: 1rem;
    /* background-color: red; */
    /* display: grid;  */
    /* grid-row: 1; */
    /* grid-area: baglantilarCls; */
    /* row-gap: 0.5rem;
    margin: 0 auto; */ /* bu yatayda ortalıyor */
}

.linkBasligiCls {
    font-size: 1.5rem;
    font-weight: 700;
}

.linkBaglantisiCls {
    margin: 0;
    color: var(--colorPrimaryDark);
}