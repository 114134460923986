* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root {
    --colorPrimary1:#FFDACC;
    --colorPrimary2:#FFBDA5;
    --colorPrimary3:#FFA27F;
    --colorPrimary4:#DC7650;
    --colorPrimary5:#DC7650;
    --colorSecondary1-1:#FFE9CC;
    --colorSecondary1-2:#FFD8A5;
    --colorSecondary1-3:#FFC77F;
    --colorSecondary1-4:#DC9F50;
    --colorSecondary1-5:#BD7D2B;
    --colorSecondary2-1:#F7C6D5;
    --colorSecondary2-2:#ED99B4;
    --colorSecondary2-3:#E27195;
    --colorSecondary2-4:#C3476F;
    --colorSecondary2-5:#A7264F;

    --colorPrimaryDark: #7D2735;
    --colorPrimaryWhite:#FFFFFF;
    --colorPrimaryRed:#FF0000;
    --colorLightGold:#FF9800 ;
    --colorBlack:#000000;

    --transitionLength: 0.4s;
    --transitionTiming: ease;
}

body {
    background-color: var(--colorPrimary1);
    color: var(--colorPrimaryWhite);
}

img {
    width: 100%;
}

main {
    overflow: scroll;
}

a {
    text-decoration: none;
    color: var(--colorBlack);
}

a:hover {
    color: var(--colorPrimaryWhite);
}

address {
    display: block;
    font-style: italic;
}

.kokYerlesimCls {
    display: grid;
    /* grid-template-columns: repeat(12, 1fr); */
    grid-template-areas: 
    "navBarCls navBarCls navBarCls navBarCls navBarCls navBarCls navBarCls navBarCls navBarCls navBarCls navBarCls navBarCls"
    "anaBolum anaBolum anaBolum anaBolum anaBolum anaBolum anaBolum anaBolum anaBolum anaBolum anaBolum anaBolum"
    "footer footer footer footer footer footer footer footer footer footer footer footer";
    grid-template-rows: 0fr 1fr 0fr;
    margin: 0 auto;
    /* min-height: 100vh; */
    height: 100vh;
    overflow: hidden;
}

.anaBolum {
    width: 100vw;
    color: var(--colorBlack);
    /* display: grid; */
    grid-area: anaBolum;
    /* grid-template-columns: 0fr;
    grid-template-rows: 1fr; */
    overflow: auto;
    scrollbar-width: none;
    /* background-color: black; */
}

.anaBolum::-webkit-scrollbar {
    display: none;
}

.boldRedSpan {
    color: var(--colorPrimaryRed);
    font-weight: 500;
}

.boldGreenSpan {
    color: green;
    font-weight: 1000;
    font-size: larger;
}

.inlineBlockCls {
    display: inline-block;
}

.greenBackgroundCls {
    color: white;
    border-radius: 15%;
    padding: 0 1em;
    background-color: forestgreen;
    border: none;
}

.animationCls {
    display: grid;
}

@media only screen and (max-width: 720px) {
    .kokYerlesimCls {
        height: 100dvh;
    }
}