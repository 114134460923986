.telefonVeWhatsAppCls {
    position: relative;
    padding: 15rem;
    text-align: center;
    background-color: var(--colorSecondary2-5);
    color: var(--colorPrimaryWhite);
    display: block;
    width: 30vw;
    margin: 0;
    padding: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.telefonVeWhatsAppCls a {
    color: var(--colorPrimaryWhite);
}