.etiketlerCls {
    overflow:hidden;
    margin-top: 2rem;
    padding-left: 0;
    width: 100vw;
}

.etiketlerCls > a {
    display: inline-block;
    color: var(--colorPrimaryDark);
}