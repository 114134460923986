.hosgeldinizArkaPlanCls {
    height: 100vh;
    width: 100vw;
    background-color: rgba(80, 80, 80, 0.2);
    position: fixed;
    display: block;
    top: 0px;
    z-index: 1000;
}

.hosgeldinizIcericiCls {
    position: relative;
    display: block;
    width: 30vw;
    margin: 0;
    padding: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 720px) {
    .hosgeldinizIcericiCls {
        width: 75vw;
        /* padding-bottom: 25rem; */
    }
}