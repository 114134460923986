.navBarCls {
    display: grid;
    grid-area: navBarCls;
    width: 100vw;
    grid-template-columns: minmax(15rem, auto) 1fr auto minmax(10rem, auto);
    grid-template-rows: minmax(3.5rem, auto);
    grid-template-areas: "navLeftSideCls navCenterSideCls navLoginCls navLanguageCls";
    /* background-color: transparent; */
    background-color: var(--colorPrimary5);
}

.navBarCls a {
    color: var(--colorPrimaryWhite);
}

a {
    margin-left: 0.5rem;
}

.invisibleCls {
    display: none !important;
}

/*.headerCls {
    display: grid;
    grid-template-areas: 
    "navBar navBar navBar navBar navBar navBar navBar navBar navBar navBar navBar navBar";
    grid-area: headerCls;
    grid-template-rows: minmax(50px, auto);
    padding: 1rem 0;
}*/

.navLeftSideCls {
    display: grid;
    grid-area: navLeftSideCls;
}

.navCenterSideCls {
    display: grid;
    grid-area: navCenterSideCls;
    grid-template-areas: "navAboutCls navOurServicesCls navLinksCls navNewsCls navContactUsCls navLoginCls navLanguageCls nav-btn";
}

.navAboutCls {
    display: grid;
    grid-area: navAboutCls;
}

.navLeftSideCls a {
    width: max-content;
}

.navOurServicesCls {
    /* display: none; */
    /* grid-area: navOurServicesCls; */
    height: fit-content;
    width: fit-content;
}

.altMenuCls {
    position: relative;
    left: 2rem;
    display: grid;
    grid-area: altMenuCls;
}

.navLinksCls {
    display: grid;
    grid-area: navLinksCls;
}

.navNewsCls {
    display: grid;
    grid-area: navNewsCls;
}

.navContactUsCls {
    display: grid;
    grid-area: navContactUsCls;
}

.navLoginCls {
    display: grid;
    width: max-content;
    grid-template-columns: max-content;
    grid-area: navLoginCls;
    justify-self: end;
    margin-right: 1rem;
}

.navLanguageCls {
    display: grid;
    grid-template-columns: minmax(5rem, max-content);
    grid-area: navLanguageCls;
    justify-self: end;
    /* margin-right: 1rem; */
}

.langButtonCls {
    display: inline-grid;
    background-color: transparent;
    border: none;
    justify-self: start;
}

.nav-btn {
    display: grid;
    background-color: transparent;
    border: none;
    grid-area: navButton;
    justify-self: right;
    width: auto;
    /* padding-right: 1em;
    padding-top: 1em; */
}

/*nav .nav-btn {
    cursor: pointer;
    visibility: hidden;
    display: none;
} */

.navBarDropDownServicesCls {
    position: relative;
    list-style-type: none;
    width: 100%;
}

.navBarDropDownServicesOpenCls {
    position: relative;
    list-style-type: none;
    width: max-content;
}

.navBarDropDownCls {
    position: relative;
    list-style-type: none;
    width: max-content;
    height: auto;
    z-index: 1000;
}

.navBarDropDownServicesCls ul {
    display: none;
    list-style-type: none;
    height: 0;
}

.navBarDropDownServicesOpenCls ul {
    display: grid;
    list-style-type: none;
    height: "100%";
}

.navBarDropDownCls ul {
    display: none;
    list-style-type: none;
    height: 0;
}

#navLanguageID, #navLoginID {
    justify-self: end;
}
#navLanguageID {
    width: 10ch;
}

/* .altMenuCls {
    display: none;
    position: relative;
    left: 0%;
    list-style-type: none;
    height: 0;
} */

.altMenuShowCls {
    position: absolute;
    display: grid;
    grid-area: altMenuOpenCls;
    left: 0px;
    margin-left: 75%;
    list-style-type: none;
    height: 100%;
    width: 100%;
}

@media only screen and (min-width: 720px) and (max-width: 1150px) {
    .navBarCls {
        grid-template-columns: auto 1fr auto auto;
        /* grid-template-columns: auto auto auto auto 1fr; */
    }
    .navBarCls div {
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (max-width: 720px) {
    .navBarCls {
        grid-template-areas: "navLeftSideCls navCenterSideCls navLoginCls navLanguageCls navButton";
        grid-template-columns: auto auto auto auto 1fr;
    }
    .navLeftSideCls {
        width: 100%;
    }
}