footer {
    width: 100vw;
    display: inline-grid;
    grid-template-columns: auto 1fr;
    grid-area: footer;
    grid-template-areas: 
    "telifHakkiCls sagAltKoseCls";
    /* background-color: transparent; */
    background-color: var(--colorPrimary5);
}

.telifHakkiCls {
    display: inline-grid;
    width: max-content;
    grid-area: telifHakkiCls;
}