.sagAltKoseCls {
    display: inline-grid;
    justify-self: end;
    text-align: center;
    grid-area: sagAltKoseCls;
    min-width: 15rem;
    max-width: 25rem;
}

.sagAltKoseCls > button {
    max-height: 1.5rem;
    min-width: 15rem;
    max-width: 25rem;
}

.yukariAcilirMenuKalemleri {
    /* display: none; */
    display: block;
    position: absolute;
    bottom: 1.5rem;
    right: 0;
}

.yukariAcilirMenuKalemleri.greenBackgroundCls {
    border-radius: 0%;
    text-align: end;
}

.yukariAcilirMenuKalemi {
    display: block;
    color: white;
}

@media only screen and (max-width: 720px) {
    .sagAltKoseCls {
        min-width: 5rem;
        max-width: 15rem;
    }
    .sagAltKoseCls > button {
        max-height: 1.5rem;
        min-width: 5rem;
        max-width: 15rem;
    }
}