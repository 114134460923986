.profileHeaderOneCls {
    margin-left: 1rem;
    margin-top: 1rem;
}
.profileParagraphCls {
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.inlineBlockCls {
    display: inline-block;
}